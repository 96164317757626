import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import logo from "../sbslogo.png";
import tele from "./tele.png"


const useStyles = makeStyles((theme) => ({
  navlinks: {
    // marginLeft: theme.spacing(5),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    textAlign:"center",
    // marginRight: theme.spacing(2),
    maxWidth:'83%',
    width:'420px',
    minWidth:'213px',
    lineHeight:'1px',
   

  },
  banner:{
    color:"black",
    fontWeight:"bold",
    marginLeft:'auto',
    fontStyle:'italic',
    fontSize:"25px"
  },
  tele:{
    maxWidth:'27px'
  },
  
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "20px",
    // marginLeft: theme.spacing(9),
    marginRight: theme.spacing(12),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="static" style={{ background: 'white'}}>
      <CssBaseline />
      <Toolbar style={{minHeight:'117px', boxShadow: '0 5px 5px rgba(0,0,0,.4)'}}>
        {isMobile ? (
          <React.Fragment>
          <DrawerComponent />
          <Typography variant="h4" className={classes.logo}>
            <img src={logo} alt="sterling beauty school" className={classes.logo} style={{border: '3px solid black', padding: '11px', borderRadius: '8px'}} />      
          <div style={{color:'black', position: 'relative', fontSize: '13px', display:'block',top:'13px', fontStyle:"italic"}}>"A Leading Beauty School in Virginia"</div>
        </Typography>
          <Typography style={{padding: 12}}>
          <a href="tel:703-297-7589"><img src={tele} alt="sterling beauty school" className={classes.tele} /></a>
          </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
          <Typography variant="h4" className={classes.logo} style={{ marginRight: theme.spacing(2)}}>
          <img src={logo} alt="sterling beauty school" className={classes.logo} style={{border: '3px solid black', padding: '11px', borderRadius: '8px'}}  />  
          <div style={{color:'black', position: 'relative', fontSize: '13px', display:'block',top:'13px', fontStyle:"italic"}}>"A Leading Beauty School in Virginia"</div>
     
      </Typography>
          <div className={classes.navlinks} style={{display:'inline-block'}}>
            <Link to="/" className={classes.link}>
              Home
            </Link>
            <Link to="/about" className={classes.link}>
              About
            </Link>
            <Link to="/courses" className={classes.link}>
              Courses
            </Link>
            {/* <Link to="/admissions" className={classes.link}>
              Admissions
            </Link> */}
            <Link to="/contact" className={classes.link}>
              Contact
            </Link>
'            <div className={classes.banner} style={{position: 'relative', fontSize: 18, display: 'block', top: 13, fontStyle: 'italic', width: '100%', textAlign: 'right'}}>
           Need Help? Call Us: 703-297-7589         
           </div>
          </div>
          
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
