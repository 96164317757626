import React from "react";
import { BrowserRouter as Router, Switch, Route,Redirect } from "react-router-dom";
import Navbar from "./component/Navbar";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
// import Admissions from "./pages/admissions";
import Courses from "./pages/courses";
import Barbering from "./pages/barbering";
import Footer from "./component/Footer"
import My404Component from "./pages/My404Component"

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        {/* <Route path='/courses/:barbering' component={Barbering} /> */}
        <Route path="/courses" component={Courses} />
        <Route path='/404' component={My404Component} />
<Redirect from='*' to='/404' />
      </Switch>
    <Footer />
    </Router>
  );
}
export default App;
