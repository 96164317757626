import { Typography } from "@material-ui/core";
import React from "react";
import "../app.css"

function My404Component() {
  return     <React.Fragment>
<body>
	<h1 style={{textAlign:'center', padding:20}}>Page Not Found</h1>
	
</body>
</React.Fragment>
}
export default My404Component;
