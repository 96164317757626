import React from 'react';
import '../footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, faLinkedin,faInstagram } from "@fortawesome/free-brands-svg-icons"


<FontAwesomeIcon icon={faFacebook} />

const Footer = () => {
  return (
    <footer className="footer-distributed">
			<div className="footer-left">
				<h4>STERLING BEAUTY SCHOOL</h4>
				<div className="footer-links">
					<a href="#" className="link-1">Home</a>
					<a href="#">About</a>
					<a href="#">Courses</a>
					<a href="#">Admissions</a>
					<a href="#">Contact</a>
				</div>
			</div>
			<div className="footer-center">
				<div>
					<i className="fa fa-map-marker"></i>
					<p><span>21580 Atlantic Blvd, Suite 110</span> Sterling, Virginia</p>
				</div>
				<div>
					<i className="fa fa-phone"></i>
					<p>+1.703.297.7589</p>
				</div>
				<div>
					<i className="fa fa-envelope"></i>
					<div><a href="mailto:support@company.com" style={{color: 'white', fontWeight: 'normal'}}>info@sterlingbeautyschool.com</a></div>
				</div>
			</div>
			<div className="footer-right">
				<span className="footer-company-about" style={{padding:20, display:'inline-block'}}>
					<span>About Sterling Beauty School</span>
					Sterling Beauty School is a premier beauty school located in Virginia. We offer comprehensive training programs in cosmetology, esthetics, and nail technology. Our experienced instructors are dedicated to providing students with the skills and knowledge necessary to succeed in the beauty industry.
				</span>
				<div className="footer-icons" style={{display:'inline-block'}}>
					<a href="#"><FontAwesomeIcon icon={faFacebook} /></a>
					<a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
					<a href="#"><FontAwesomeIcon icon={faLinkedin}/></a>
					<a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
				</div>
                <br/>
				<p className="footer-company-name" style={{textAlign:'center'}}>Sterling Beauty School © 2025</p>
			</div>
		</footer>
  );
};

export default Footer;