import { Typography } from "@material-ui/core";
import React from "react";
import "../app.css"

function About() {
  return (
    <React.Fragment>
      <title>About Sterling Beauty School</title>
		<h1 style={{textAlign:'center', padding:20}}>About Sterling Beauty School</h1>
	<main>
  {/* <h2 style={{textAlign:'center', padding:20}}>About Sterling Beauty School</h2> */}
		<h2>Our Mission</h2>
		<p>At Sterling Beauty School, our mission is to provide our students with the knowledge, skills, and tools they need to succeed in the beauty industry. We believe in providing a comprehensive education that combines hands-on training with theoretical knowledge, so that our students are fully prepared for a successful career in the beauty industry.</p>
    <h2>Our Programs</h2>
	<p>We offer a range of programs that cover all aspects of the beauty industry, including cosmetology, barbering, esthetics and nail technology. Our programs are designed to provide students with the knowledge and skills they need to pass their state licensing exams and start successful careers in the beauty industry.</p>
	
	<h2>Our Faculty</h2>
	<p>Our faculty members are experienced professionals who are passionate about the beauty industry and committed to providing our students with a top-notch education. They bring their real-world experience and expertise to the classroom, so that our students can learn from the best.</p>
	
	<h2>Our Facilities</h2>
	<p>We have state-of-the-art facilities that are equipped with the latest tools and equipment, so that our students can get hands-on experience with the same equipment they'll use in their careers. Our facilities are designed to provide a comfortable and inspiring learning environment, where students can focus on their studies and develop their skills.</p>
	
	<h2>Get in Touch</h2>
	<p>If you're interested in learning more about Sterling Beauty School and our programs, please don't hesitate to get in touch with us. We'd be happy to answer any questions you have and help you get started on your path to a successful career in the beauty industry.</p>

</main>

</React.Fragment>
  )
}
export default About;
