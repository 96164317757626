import { Typography, Box, BoxProps,makeStyles,useTheme,useMediaQuery,} from "@material-ui/core";
import img1 from '../img1.jpg'
import img2 from '../img2.jpg'
import img3 from '../img3.jpg'
import img4 from '../img4.jpg'
import img6 from '../img6.jpg'
import img7 from '../img7.jpg'
import img8 from '../img8.jpg'
import "../app.css"


const useStyles = makeStyles((theme,isMobile)=>({
  
  blockHeaders:{
    textAlign:"center",
    fontSize:20,
    maxWidth:'100%',maxHeight:"100%"
  },
  homeImg:{
    maxWidth:'100%',
    maxHeight:"100%",
    borderRadius: 10,
    padding: !isMobile ? 5 : 50
  }

}))


function Item(props: BoxProps) {
  const { sx, ...other } = props;

  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        // border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        // p: 1,
        // borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

function Home() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes =useStyles();
  let sxObj =  isMobile ?({
    display: 'grid',
    // columnGap: 3,
    // rowGap: 4,
    gridTemplateColumns: 'repeat(2, 1fr)',
  } ):{
    display: 'grid',
    // columnGap: 3,
    // rowGap: 4,
    gridTemplateColumns: 'repeat(3, 1fr)',
  } ;
  return (
  <body className={"bodyMain"} style={{ backgroundColor:'white', color:'black'}}>
  <Box
      sx={sxObj}
  >
  <Item className={classes.blockHeaders}>Cosmetology <br/><img className={"homeImg"} src={img3}  alt="sterling beauty school" /></Item>
  <Item className={classes.blockHeaders}>Barbering <br/><img className={"homeImg"} src={img4}  alt="sterling beauty school" /></Item>
  <Item className={classes.blockHeaders}>Esthetics <br/><img className={"homeImg"} src={img6}  alt="sterling beauty school" /></Item>
  <Item className={classes.blockHeaders}>Nails <br/><img className={"homeImg"} src={img2}  alt="sterling beauty school" /></Item>
  <Item className={classes.blockHeaders}>Theory <br/><img className={"homeImg"} src={img8} style={{minWidth:'100%'}} alt="sterling beauty school" /></Item>
  <Item className={classes.blockHeaders}>Seminars <br/><img className={"homeImg"} src={img7}  alt="sterling beauty school" /></Item>
</Box>


<Typography >

<div className="main" style={{padding: '0px 40px 40px 40px'}}>
		<h2 style={{textAlign:'center'}}>Welcome to Sterling Beauty School</h2>
		<span>Sterling Beauty School is a leading beauty school in Virginia, offering a range of programs to help you launch your career in the beauty industry.</span>
		<span>Our programs include:</span>
		<ul>
			<li>Cosmetology</li>
			<li>Barbering</li>
			<li>Esthetics</li>
			<li>Nail Technology</li>
			<li>Theory-only</li>
			<li>Seminars</li>
		</ul>
		<span>At Sterling Beauty School, our experienced instructors are dedicated to helping you develop the skills and knowledge you need to succeed in your chosen career path. We offer flexible scheduling options and financial aid to make your education more accessible.</span>
		<span>Contact us today to learn more about our programs and how we can help you achieve your career goals.</span>
	</div>
</Typography>
  </body>
  )

}
export default Home;