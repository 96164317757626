import { Typography } from "@material-ui/core";
import React from "react";
import "../app.css"

function Courses() {
  return     <React.Fragment>
    <title>Sterling Beauty School Courses</title>
<body>
	<h1 style={{textAlign:'center', padding:20}}>Sterling Beauty School Courses</h1>
	<div class="course">
		<h2>Cosmetology</h2>
		<p>Our cosmetology program covers the art and science of hair, skin, and nail care. You will learn the latest techniques in cutting, coloring, and styling hair, as well as skincare and makeup application. The program also covers nail technology, including manicures, pedicures, and nail enhancements. Upon completion of the program, you will be prepared to take the state licensing exam and start your career as a licensed cosmetologist.</p>
		<p><span>Duration:</span> 1500 hours</p>
	</div>
	<div class="course">
		<h2>Barbering</h2>
		<p>Our barbering program focuses on men's grooming and hairstyling. You will learn how to cut, style, and shave men's hair, as well as how to create trendy and classic looks. The program also covers beard and mustache grooming, as well as skin care and scalp treatments. Upon completion of the program, you will be prepared to take the state licensing exam and start your career as a licensed barber.</p>
		<p><span>Duration:</span> 1100 hours</p>
	</div>
	<div class="course">
		<h2>Esthetician</h2>
		<p>Our esthetics program covers the art and science of skin care. You will learn how to perform facials, chemical peels, and microdermabrasion, as well as how to analyze skin and recommend appropriate treatments. The program also covers makeup application, hair removal, and lash and brow tinting. Upon completion of the program, you will be prepared to take the state licensing exam and start your career as a licensed esthetician.</p>
		<p><span>Duration:</span> 600 hours</p>
	</div>
	<div class="course">
		<h2>Nail Technician</h2>
		<p>Our nail technology program covers the art and science of nail care. You will learn how to perform manicures, pedicures, and nail enhancements, as well as how to analyze nails and recommend appropriate treatments. The program also covers nail art and design, as well as sanitation and safety procedures. Upon completion of the program, you will be prepared to take the state licensing exam and start your career as a licensed nail technician.</p>
		<p><span>Duration:</span> 150 hours</p>
	</div>
	<div class="course">
		<h2>Theory</h2>
		<p>	The theoretical section of these courses aims to provide students with knowledge related to various aspects of the beauty industry. For Cosmetology we offer a Theory-only class for those who are already prepared for the practical examination and need to prepare for the theory examination.</p>
		<p><span>Duration:</span> 150 hours</p>
	</div>
	<div class="course">
		<h2>Seminars</h2>
		<p>	Sterling Beauty School hosts industry known artists and influencers for seminars on extended learning topics like colorimetry and advanced cutting techniques and trends. Current students receive discounted pricing to attend these additional training courses.</p>
		{/* <p><span>Duration:</span> 150 hours</p> */}
	</div>
  <h2>Admission Requirements</h2>
<ul>
	<li>Must be at least 18 years old</li>
	<li>Must have a high school diploma or GED</li>
	<li>Must provide proof of citizenship or legal residency</li>
	<li>Must complete an admissions application and pay a non-refundable application fee</li>
	{/* <li>Must meet with an admissions representative for an interview and tour of our facilities</li> */}
</ul>

<p>If you meet these requirements and are interested in pursuing a career in the beauty industry, we encourage you to apply to Sterling Beauty School today!</p>

</body>
</React.Fragment>
}
export default Courses;
