import { Typography } from "@material-ui/core";
import "../contact.css"
import dotLocation from '../location-dot-solid.svg'
import email from '../envelope-solid.svg'
import phone from '../phone-solid.svg'

function Contact() {
  return(
    <section id="contact-us" style={{textAlign:'center', paddingBottom:450}}>
    <h2>Contact Us</h2>
    <div class="contact-info">
      <p><img  src={dotLocation} style={{maxWidth:25, maxHeight:25}} alt="sterling beauty school location" /> 21580 Atlantic Blvd, Suite 110, Sterling, VA 20166</p>
      <p><img  src={phone} style={{maxWidth:25, maxHeight:25}} alt="sterling beauty school email" />(703) 297-7589</p>
      <p><img  src={email} style={{maxWidth:25, maxHeight:25}} alt="sterling beauty school email" /> info@sterlingbeautyschool.com</p>
    </div>
    <div class="hours-of-operation" style={{backgroundColor:' #f5f5f5'}}>
      <h3>Hours of Operation</h3>
      <p>Monday - Friday: 9am - 5pm</p>
      <p>Saturday: 9am - 4pm</p>
      <p>Sunday: Closed</p>
      

    </div>
  </section>
  )
}
export default Contact;
