import "./barbering.css"

function Barbering() {
  return(
    <body>
	<header>
		<h1>Barber School Syllabus</h1>
	</header>
	<main>
		<h2>Courses</h2>
		<table>
			<thead>
				<tr>
					<th>Course Name</th>
					<th>Description</th>
					<th>Duration</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Barbering Basics</td>
					<td>Introduction to barbering tools, techniques, and sanitation practices.</td>
					<td>2 weeks</td>
				</tr>
				<tr>
					<td>Clipper Cutting</td>
					<td>Advanced clipper techniques for men's haircuts.</td>
					<td>2 weeks</td>
				</tr>
				<tr>
					<td>Scissor Cutting</td>
					<td>Advanced scissor techniques for men's and women's haircuts.</td>
					<td>2 weeks</td>
				</tr>
				<tr>
					<td>Shaving and Beard Design</td>
					<td>Techniques for hot towel shaves, beard trimming, and grooming.</td>
					<td>2 weeks</td>
				</tr>
				<tr>
					<td>Hairstyling and Updos</td>
					<td>Advanced techniques for women's haircuts and styling.</td>
					<td>2 weeks</td>
				</tr>
				<tr>
					<td>Business Management</td>
					<td>How to run a successful barber shop, manage employees, and market your business.</td>
					<td>2 weeks</td>
				</tr>
			</tbody>
		</table>
	</main>
</body>
  )
}
export default Barbering;
